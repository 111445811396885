.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  text-rendering: optimizeLegibility;
  color: #e3e3e3;
  position: relative;
}

.light.app {
  color: #333;
}
