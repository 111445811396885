.sidebar {
  min-width: 248px;
  height: 100vh;
  padding: 74px 2px 0 35px;
  background-color: #282828;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: fixed;
  left: 0;
  z-index: 300;
  transition: left linear 0.1s;
}
@media only screen and (max-width: 56.25em) {
  .sidebar {
    padding-top: 60px;
 }
}
.sidebar-clickables {
  height: fit-content;
}
.hide-sidebar .sidebar {
  left: -284px !important;
}
@media only screen and (max-width: 56.25em) {
  .sidebar__overlay {
    background-color: rgba(0, 0, 0, 0.65);
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 299;
    transition: display ease 0.1s;
 }
  .hide-sidebar .sidebar__overlay {
    display: none;
 }
}
.logo {
  height: 24px;
  width: 100%;
  background-color: black;
  margin-bottom: 2rem;
}
.project-group {
  display: flex;
  flex-direction: row;
  padding: 5px 12px 5px 7px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.project-group .menu__trigger {
  right: 12px;
}
.project-group:hover {
  background-color: #363636;
  border-radius: 5px;
}
.project-group:hover .menu__trigger {
  opacity: 1;
  padding: 0;
}
.project-group.active {
  background-color: #363636;
  border-radius: 5px;
}
.project-group__group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-group__icon {
  width: fit-content;
  margin-right: 0.6rem;
  align-items: center;
  display: flex;
}
.project-group__count {
  border-radius: 50%;
  height: 12px;
  width: 12px;
  display: flex;
  place-items: center;
  justify-content: center;
  font-size: 10px;
}
.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.5rem;
}
.label__dot {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.add-project {
  height: fit-content;
}
.add-project__container {
  margin-left: 1.5rem;
}
.add-project input {
  height: 1.2rem;
  width: -webkit-fill-available;
  margin-bottom: 1rem;
  font-size: 11px;
  height: 1.6rem;
  border: #fff 1a 1px solid;
  background-color: transparent;
  color: gray;
  border-radius: 5px;
}
.add-project__buttons {
  display: flex;
  justify-content: space-between;
}
.add-project__buttons button {
  width: 45%;
  font-size: 11px;
  height: 1.6rem;
  border: #fff 1a 1px solid;
  background-color: transparent;
  color: gray;
  border-radius: 5px;
}
.task-count {
  color: #777;
  font-size: 12px;
  font-weight: 100;
  width: 24px;
  text-align: center;
}
.task-count__red {
  color: #ff7f74;
}
.custom-project {
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  padding: 5px 12px 5px 7px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.custom-project.active {
  background-color: #363636;
  border-radius: 5px;
}
.custom-project__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-project__icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.6rem;
}
.custom-project__info {
  display: flex;
  align-items: center;
}
.custom-project__dot {
  width: 10px;
  height: 10px;
  background-color: #fefbfb;
  border-radius: 50%;
}
.custom-project__name {
  margin: 0.4rem 0;
}
.custom-project-group__title-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  padding: 5px 12px 5px 7px;
  cursor: pointer;
}
.custom-project-group__title-group:hover .custom-project-group__add-project {
  opacity: 1;
}
@media only screen and (max-width: 56.25em) {
  .custom-project-group__title-group .custom-project-group__add-project {
    opacity: 1;
 }
}
.custom-project-group__icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
}
.custom-project-group__name {
  font-weight: 700;
}
.custom-project-group__wrapper {
  margin-bottom: 1rem;
}
.custom-project-group__add-project {
  margin-left: auto;
  cursor: pointer;
  opacity: 0;
  display: flex;
  place-items: center;
  padding: 0.2rem;
  background-color: unset;
}
.custom-project-group__add-project svg {
  stroke: #ddd;
}
.custom-project-group__add-project:hover {
  background-color: #363636;
}
.custom-project-group__add-project--icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-content: center;
  border-radius: 2px;
}
