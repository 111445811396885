.board__wrapper {
  margin-left: 47px;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding-bottom: 4rem;
  scrollbar-width: none;
}
.board__wrapper::-webkit-scrollbar {
  display: none;
}
.board-column__title {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.board-column__container {
  min-width: 250px;
}
.board-column__container:not(:last-of-type) {
  margin-right: 1rem;
}
.board-column__container .tasklist {
  min-height: 1px;
}
.board-task {
  padding: 12px;
  display: flex;
  background-color: #282828;
  width: -webkit-fill-available;
  margin-bottom: 0.6rem;
  border-radius: 5px;
  border: 1px solid #fff 1a;
  box-shadow: 0 10px 10px #000 1a, 0 6px 3px #000 1f;
  position: relative;
  align-items: center;
}
.board-task .menu__trigger {
  right: 0.6rem;
}
.board-task:hover {
  border-color: #fff 33;
}
.board-task:hover .menu__trigger {
  opacity: 1;
}
@media only screen and (max-width: 37.5em) {
  .board-task .menu__trigger {
    display: none;
 }
}
.board-task__info {
  margin-top: 8px;
}
.board-task .task__checkbox {
  align-self: flex-start;
}
