.add-task {
  margin: 0.4rem 0;
  background-color: inherit;
}
.add-task__container {
  border: 1px solid #383838;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 10px;
  cursor: text;
}
.add-task__input {
  width: -webkit-fill-available;
  margin-bottom: 1rem;
  font-size: 12px;
  height: 1.6rem;
  border: none;
  font-weight: 600;
  background-color: transparent;
  color: #ffffde;
  border-radius: 5px;
}
.add-task__input:focus {
  outline: unset;
}
.add-task__attributes {
  display: flex;
  justify-content: space-between;
}
.add-task__attributes--left, .add-task__attributes--right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.add-task__actions {
  background-color: inherit;
}
.add-task__actions button:not(:last-child) {
  margin-right: 0.4rem;
}
.add-task__actions--add-task {
  background-color: #de4c4a;
  color: #feffff;
}
.add-task__actions--cancel {
  background-color: #f5f5f5;
  border: 1px solid #555;
}
.add-task__toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #808080;
  margin-top: 0.6rem;
}
.add-task__toggle:hover {
  color: #de4c4a;
}
.add-task__toggle:hover .add-task__icon {
  background-color: #de4c4a;
}
.add-task__toggle:hover .add-task__icon svg {
  stroke: #feffff;
}
.add-task__icon {
  border-radius: 50%;
  margin-right: 0.4rem;
  display: flex;
  place-items: center;
}
.add-task__icon svg {
  stroke: #de4c4a;
}
.set-new-task__project, .set-new-task__schedule {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  border: 1px #555 solid;
  border-radius: 5px;
  font-size: 12px;
  position: relative;
}
.set-new-task__project:not(:last-child), .set-new-task__schedule:not(:last-child) {
  margin-right: 0.4rem;
}
.set-new-task__project svg, .set-new-task__schedule svg {
  margin-right: 0.2rem;
}
.set-new-task__label {
  margin: 0 0.4rem;
}
.quick-add__container {
  margin-bottom: unset !important;
  border-bottom: unset;
}
