.light .add-project__modal {
  background-color: #fff;
}
.light .add-project__modal--content {
  background-color: #fff;
}
.light .add-project__modal header h4 {
  color: black;
}
.light .add-project__modal label {
  color: black;
}
.light .add-project__project-name, .light .add-project__select-color, .light .add-project__set-selected-color {
  border: 1px solid #ddd;
  background-color: #fff;
  color: inherit;
}
.light .add-project__set-view-type--preview.preview__list {
  background-image: url("../../../../assets/svg/list-light.svg") !important;
}
.light .add-project__set-view-type--preview.preview__list:hover {
  background-image: url("../../../../assets/svg/list-hover-2.svg") !important;
}
.light .add-project__set-view-type--preview.preview__board {
  background-image: url("../../../../assets/svg/board-light.svg") !important;
}
.light .add-project__set-view-type--preview.preview__board:hover {
  background-image: url("../../../../assets/svg/board-hover-2.svg") !important;
}
.light .add-project__modal--footer {
  border-top: 1px solid #ddd;
}
.light .add-project__modal--header {
  border-bottom: 1px solid #ddd;
}
.light .add-project__set-view-type--preview {
  border: 1px solid #ddd;
}
.light .btn__cancel {
  background-color: inherit;
  border: 1px solid #ddd;
  color: inherit;
}
.light .btn__cancel:hover {
  color: #333;
  border-color: #999;
  box-shadow: 0 1px 2px #000 26;
  text-decoration: none !important;
}
.light .add-project__set-view-type .selected.add-project__set-view-type--option .add-project__set-view-type--preview {
  border-color: #d1453b;
}
.light .add-project__set-view-type .selected.add-project__set-view-type--option .add-project__set-view-type--radio {
  background-color: #d1453b;
}
