.add-project {
  border-radius: 5px;
}
.add-project__modal {
  border-radius: 5px;
  width: 400px;
  background-color: #202020;
  box-shadow: 0 2px 8px 0 #000 29;
  align-self: center;
}
@media only screen and (max-width: 37.5em) {
  .add-project__modal {
    top: 8%;
    width: 92%;
    position: inherit;
 }
}
.add-project__modal--header {
  padding: 20px 24px;
  border-bottom: 1px solid #fff 1a;
}
.add-project__modal--header h4 {
  margin: unset;
  font-size: 16px;
}
.add-project__modal--content {
  padding: 20px 24px;
  background-color: #282828;
  height: 40vh;
  overflow-y: scroll;
}
.add-project__modal--footer {
  padding: 20px 24px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #fff 1a;
  border-bottom: unset;
}
.add-project__modal--buttons {
  display: flex;
  justify-content: end;
}
.add-project__modal--buttons .btn__add-project, .add-project__modal--buttons .btn__cancel {
  padding: 0.6rem 1rem;
  font-weight: 600;
  border-radius: 5px;
  color: #fefbfb;
}
.add-project__modal--buttons .btn__add-project {
  background-color: tomato;
}
.add-project__modal--buttons .btn__cancel {
  margin-right: 0.4rem;
  background-color: #353535;
  transition-property: box-shadow, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.add-project__form-group label {
  display: block;
  margin-bottom: 0.4rem;
  font-weight: 700;
  font-size: 13px;
}
.add-project__form-group:not(:last-child) {
  margin-bottom: 1rem;
}
.add-project__project-name, .add-project__select-color {
  width: -webkit-fill-available;
  color: #eee;
  border: 1px solid #333;
  background-color: #202020;
  padding: 0.4rem;
  position: relative;
  border-radius: 5px;
}
.add-project__select-color {
  display: flex;
  align-items: center;
}
.add-project__selected-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 0.4rem;
}
.add-project__set-selected-color {
  position: absolute;
  height: 200px;
  overflow-y: scroll;
  background-color: #282828;
  width: inherit;
  padding: 1rem 0.4rem;
  top: 2rem;
  left: 0;
  border-radius: 5px;
}
.add-project__colour-dropdown {
  box-shadow: 0 10px 20px #000 30, 0 6px 6px #000 3b;
  border: 1px solid #171717;
}
.add-project__colour-dropdown--option {
  display: flex;
  padding: 0.4rem 0;
  cursor: pointer;
}
.add-project__colour-dropdown--option:not(:last-child) {
  margin-bottom: 0.2rem;
}
.add-project__colour-dropdown--option-color {
  margin-right: 0.4rem;
  width: 12px;
  height: 12px;
}
.add-project__set-view-type {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.add-project__set-view-type--option {
  width: 50%;
  flex: 0;
  cursor: pointer;
}
.add-project__set-view-type--description {
  display: flex;
  align-items: center;
}
.add-project__set-view-type--radio {
  border: 1px solid grey;
 /* padding: 0.2rem;
  */
  border-radius: 50%;
  height: 13px;
  width: 13px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 0.4rem;
}
.add-project__set-view-type--radio svg {
  opacity: 0;
}
.selected .add-project__set-view-type--radio svg {
  opacity: 1;
  stroke: white;
}
.add-project__set-view-type--preview {
  border: 1px solid #fff 1a;
  background-position: 5px 5px;
  width: 160px;
  height: 120px;
  margin-bottom: 8px;
  border: 1px solid #fff 1a;
  border-radius: 5px;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 37.5em) {
  .add-project__set-view-type--preview {
    width: 34vw;
    height: 25vw;
 }
}
.add-project__set-view-type .selected.add-project__set-view-type--option .add-project__set-view-type--preview {
  border-color: tomato;
}
.add-project__set-view-type .selected.add-project__set-view-type--option .add-project__set-view-type--description > .add-project__set-view-type--radio {
  background-color: tomato;
  border: 2px solid tomato;
  color: #fefbfb;
}
.preview__list {
  background-image: url("../../../../assets/svg/1.svg");
}
.preview__list:hover {
  background-image: url("../../../../assets/svg/list-hover.svg") !important;
}
.preview__board {
  background-image: url("../../../../assets/svg/2.svg");
}
.preview__board:hover {
  background-image: url("../../../../assets/svg/board-hover.svg") !important;
}
@media only screen and (max-width: 37.5em) {
  .preview__board .preview__board, .preview__board .preview__list :hover {
    all: unset;
 }
}
.preview__list, .preview__board {
  background-size: contain;
}
