.option__overlay {
  width: 100vw;
  height: 100vh;
  z-index: 301;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
}
.menu__list {
  position: fixed;
  background-color: #282828;
  box-shadow: 0 10px 20px #000 30, 0 6px 6px #000 3b;
  border: 1px solid #171717;
  border-radius: 3px;
  padding: 2px;
  min-width: 150px;
  font-size: 13px;
}
.menu__list--item {
  padding: 0.2rem 0.6rem;
  display: flex;
  align-items: center;
  line-height: 18px;
  cursor: pointer;
}
.menu__list--item:hover {
  background-color: #4d4d4d;
  color: rgba(255, 255, 255, 0.87);
}
.menu__list--icon {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu__list--content {
  width: fit-content;
}
