.header {
  position: fixed;
  width: -webkit-fill-available;
  z-index: 310;
  top: 0;
  padding: 0 42px;
  box-shadow: 0 1px 3px 0 #000 26;
  background-color: #282828;
  display: flex;
  justify-content: space-between;
  height: 44px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.489);
}
.header__left, .header__right {
  display: flex;
  align-items: center;
}
.header__left *, .header__right * {
  display: block;
  cursor: pointer;
}
.header__left * svg, .header__right * svg {
  stroke: #ffffde;
}
.header__left *:not(:last-child), .header__right *:not(:last-child) {
  margin-right: 6px;
}
@media only screen and (max-width: 56.25em) {
  .header {
    padding: 0 12px;
 }
}
.avatar__img {
  border-radius: 50%;
  border: 1px solid grey;
}
.quick-add-task_dark {
  width: 28px;
  height: 28px;
  position: absolute;
}
