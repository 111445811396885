.view-header__board {
  padding: 0 47px;
  margin-bottom: 20px;
}
.view-header__board, .view-header__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1f1f1f;
  position: sticky;
  top: -20px;
  z-index: 100;
  height: 3rem;
  font-family: "SegoeUI", Tahoma, Geneva, Verdana, sans-serif;
}
.view-header__action {
  color: #fff 8f;
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-family: "SegoeUI", Tahoma, Geneva, Verdana, sans-serif;
}
.view-header__action:hover {
  background-color: #4d4d4d;
  color: #fff de;
}
.view-header__action:hover svg {
  stroke: #fff de;
}
.view-header__action:first-of-type {
  margin-right: 0.4rem;
}
.view-header__action svg {
  stroke: #fff 8f;
  margin-right: 0.2rem;
}
.view-header__actions--right, .view-header__actions--left {
  display: flex;
  align-items: center;
}
.view-header__actions--left small {
  color: grey;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 400;
}
