.confirm-delete {
  background-color: #1f1f1f;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  max-height: calc(100vh -2 * 13vh);
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 #000 29;
  transition: width 0.2s ease-in-out;
  max-width: 100%;
}
@media only screen and (max-width: 37.5em) {
  .confirm-delete {
    width: 80vw;
 }
}
.confirm-delete header {
  padding: 6px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirm-delete header button {
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm-delete header button:nth-child(2) svg {
  transform: rotate(45deg);
}
.confirm-delete header button:hover {
  background-color: #363636;
}
.confirm-delete__content {
  padding: 8px 16px 32px 16px;
}
.confirm-delete footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
.confirm-delete footer button:first-of-type {
  margin-right: 1rem;
}
