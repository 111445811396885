.content {
  background-color: #1f1f1f;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  padding-top: 4rem;
  margin-left: 284px;
  transition: margin linear 0.1s;
}
@media only screen and (max-width: 56.25em) {
  .content {
    margin-left: 0;
 }
}
.hide-sidebar .content {
  margin-left: 0;
}
