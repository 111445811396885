.user-options {
  position: absolute;
  z-index: 30001;
  background-color: #282828;
  padding: 2px 3px;
  box-shadow: 0 10px 20px #000 30, 0 6px 6px #000 3b;
  border: 1px solid #fff 1a;
  border-radius: 5px;
  font-size: 12px;
}
.user-options__item {
  padding: 0.4rem 3px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.user-options__item:first-child {
  border-bottom: 1px solid #fff 1a;
  border-radius: unset;
}
.user-options__item:hover {
  background-color: #363636;
}
.user-options__icon {
  margin-right: 0.4rem;
}
.user-info {
  display: flex;
}
.user-info__img {
  margin-right: 6px;
  border-radius: 50%;
  border: 1px solid grey;
}
.user-info__name {
  color: #fff de;
}
.user-info__email {
  color: #9b9b9b;
}
