.light .sidebar {
  background-color: #fafafa;
}
.light .sidebar .custom-project:hover, .light .sidebar .project-group:hover, .light .sidebar .custom-project.active, .light .sidebar .project-group.active {
  background-color: #eee;
}
.light .custom-project-group__add-project svg {
  stroke: #333;
}
.light .custom-project-group__add-project:hover {
  background-color: #eee;
}
