.ring__spinner {
  -webkit-animation-name: ring-spinner;
  animation-name: ring-spinner;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes ring-spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
 }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
 }
}
