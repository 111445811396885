.light .user-options {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 #000 14;
  border-color: #e6e6e6;
  color: inherit;
}
.light .user-options__item:first-child {
  border-color: #e6e6e6;
}
.light .user-options__item:hover {
  background-color: #f3f3f3;
}
.light .user-options .user-info__name {
  color: inherit;
}
.light .user-options .user-info__email {
  color: #9b9b9b;
}
