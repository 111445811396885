.task {
  position: relative;
  min-height: 1rem;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  align-items: flex-start;
  border-bottom: 1px solid #fff 1a;
}
.task:first-of-type {
  border-top: 1px solid #fff 1a;
}
.task:hover {
  border-color: #fff 33;
}
.task:hover .menu__trigger {
  opacity: 1;
}
.task-list__wrapper {
  padding-left: 55px;
  padding-right: 55px;
  max-width: 800px;
  margin: 0 auto;
}
.task__checkbox {
  width: 18px;
  border: grey 1px solid;
  height: 17px;
  border-radius: 50%;
  display: flex;
  place-items: center;
  justify-content: center;
  margin-right: 0.6rem;
  cursor: pointer;
}
.task__checkbox--icon {
  opacity: 0;
}
.task__checkbox--icon:hover {
  opacity: 1;
}
.task__details {
  font-size: 14px;
  width: 100%;
}
.task__info {
  display: flex;
  justify-content: space-between;
}
.task__text {
  color: #fefbfb;
  margin-bottom: 4px;
}
.task__date {
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #fff 99;
}
.task__date svg {
  margin-right: 2px;
}
.task__project-info {
  display: flex;
  font-size: 12px;
  color: #fff 99;
  align-items: center;
}
.empty-state {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empty-state__illustration {
  width: 220px;
  height: 200px;
}
.empty-state__header {
  font-size: 16px;
  color: #fff de;
  margin: 8px 0;
}
.empty-state__body {
  color: #fff 99;
  font-weight: 100;
}
@media only screen and (max-width: 56.25em) {
  .empty-state__body {
    text-align: center;
 }
}
.empty-state__button {
  background-color: #de4c4a;
  color: rgba(255, 255, 255, 0.87);
  display: block;
  font-size: 13px;
  line-height: 17px;
  margin: 20px auto 0;
  padding: 6px 12px 7px;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}
.date__today {
  color: #25b84c !important;
}
.date__tomorrow {
  color: #ff9a14 !important;
}
.date__overdue {
  color: #ff7066 !important;
}
.date__next-week {
  color: #a970ff !important;
}
.date__weekend {
  color: #5297ff !important;
}
