.auth-page {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: "Segoe UI", sans-serif;
  color: #000;
  overflow-x: hidden;
}
.auth-page__container {
  min-width: 450px;
  margin: 50px auto;
}
@media only screen and (max-width: 37.5em) {
  .auth-page__container {
    max-width: 100%;
    margin: 0;
    border: unset;
    border-radius: 0;
    height: 100%;
    min-width: unset;
    width: 100%;
 }
}
.auth-page__frame {
  margin: 50px 0;
  border-radius: 8px;
  border: solid 1px #dbdbdb;
  background: #fff;
  padding: 25px;
}
@media only screen and (max-width: 37.5em) {
  .auth-page__frame {
    margin: unset;
    height: 100vh;
 }
}
.auth-page__logo {
  text-align: left;
  margin-bottom: 14px;
}
.auth-page__content {
  box-sizing: border-box;
  color: #000;
  font-size: 13px;
  margin: auto;
  max-width: 400px;
  width: 400px;
}
@media only screen and (max-width: 37.5em) {
  .auth-page__content {
    width: unset;
    max-width: unset;
 }
}
.auth-page h1 {
  color: #000;
  font-size: 26px;
  margin: 0 0 1.1em;
  padding: 0;
}
.auth-page .field {
  margin-bottom: 10px;
}
.auth-page .label {
  display: block;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-left: unset;
}
.auth-page input {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  padding: 0.75em 1em;
  width: 100%;
}
.auth-page form {
  margin-top: 30px;
}
.auth-page hr {
  background-color: #ddd;
  border: 0;
  color: #ddd;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}
.auth-page .google-auth, .auth-page .auth-button {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 13px !important;
  font-weight: 400;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  color: #202020;
  margin-bottom: 14px;
  background: #fff;
  border: 1px solid #ddd;
  text-shadow: none;
  padding: 6px !important;
  line-height: 24px;
  min-width: 273px;
  border-radius: 3px;
}
@media only screen and (max-width: 37.5em) {
  .auth-page .google-auth, .auth-page .auth-button {
    min-width: unset;
 }
  .auth-page .google-auth span, .auth-page .auth-button span {
    text-overflow: ellipsis;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
 }
}
.auth-page .google-auth:hover, .auth-page .auth-button:hover {
  background-color: #eee;
  box-shadow: none;
}
.auth-page .google-auth img, .auth-page .auth-button img {
  margin: 0 8px 0 0 !important;
}
.auth-page .separator {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #ddd;
  height: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  position: relative;
}
.auth-page .separator .middle_separator {
  color: #777;
  font-size: 13px;
  left: 41%;
  top: -9px;
  padding: 0 16px;
  position: absolute;
  text-shadow: 0 1px 0 #fff;
  text-transform: uppercase;
  background: #fff;
}
.auth-page .toggle_password {
  position: relative;
}
.auth-page .toggle_password input[type="password"] {
  padding-right: 40px;
  width: 100%;
}
.auth-page .toggle_password .toggle {
  border-radius: 3px;
  cursor: pointer;
  height: 24px;
  margin-top: -12px;
  position: absolute;
  right: 8px;
  top: 50%;
  width: 24px;
}
.auth-page .submit-button {
  background: 0;
  background-color: #db4c3f;
  color: #fff !important;
  text-shadow: none;
  border: 1px solid transparent;
  font-weight: 700;
  position: relative;
  display: flex;
}
.auth-page .submit-button:disabled {
  opacity: 0.7;
}
.auth-page .submit-button:hover {
  border-color: #b0281a;
  background-color: #c53727;
  box-shadow: 0 1px 2px #000 26;
}
.auth-page p {
  margin: 20px;
  text-align: center;
}
.auth-page p a {
  color: #dd4b39;
  text-decoration: none;
}
.auth-page .has-error {
  border-color: #dd4b39;
}
.auth-page .error-message {
  color: #dd4b39;
  display: flex;
  padding: 0.5em 0;
}
.auth-page .error-message svg {
  margin-right: 3px;
}
.auth-page .error-message .error-block {
  margin-bottom: 1em;
}
.signup {
  overflow-x: hidden;
  color: #000;
  font-size: 13px;
  margin: auto;
  max-width: 400px;
  width: 400px;
}
@media only screen and (max-width: 37.5em) {
  .signup {
    width: 100%;
 }
}
.signup.step_one .signup__step-one {
  animation-name: visible;
}
.signup.in_step_two .signup__wrapper {
  animation-name: step-two;
}
.signup.in_step_one .signup__wrapper {
  animation-name: step-one;
}
.signup .signup__wrapper, .signup .step_one, .signup .step_two {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.signup .step_one, .signup .step_two {
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  padding: 0 3px;
}
.signup .step_two {
  visibility: hidden;
}
.signup.in_step_two .step_two {
  animation-name: visible;
}
.signup.in_step_two .step_one {
  animation-name: hidden;
}
.signup.in_step_one .signup_wrapper {
  animation-name: step-one;
}
.signup.in_step_two .signup_wrapper {
  animation-name: step-two;
}
.signup__wrapper {
  display: flex;
  position: relative;
}
.signup .backlink {
  align-items: center;
  color: #000;
  display: inline-flex;
  margin-bottom: 1em;
  padding: 0 0.5em;
  cursor: pointer;
}
.signup .backlink:hover {
  background-color: #eee;
  border-radius: 3px;
  text-decoration: none;
}
.signup .backlink svg {
  margin-left: -10px;
}
.signup .hint-text {
  margin-top: 0.5em;
  margin-bottom: 1em;
  color: #666;
}
.signup .ring__spinner {
  margin-left: 2em;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}
@keyframes visible {
  0% {
    visibility: hidden;
 }
  to {
    visibility: visible;
 }
}
@keyframes hidden {
  0% {
    visibility: visible;
 }
  to {
    visibility: hidden;
 }
}
@keyframes step-two {
  0% {
    transform: translateX(0);
 }
  to {
    transform: translateX(-100%);
 }
}
@keyframes step-one {
  0% {
    transform: translateX(-100%);
 }
  to {
    transform: translateX(0);
 }
}
