.light .content {
  background-color: #fff;
}
.light .view-header__board, .light .view-header__list {
  background-color: #fff;
}
.light .view-header__action {
  color: grey;
}
.light .view-header__action:hover {
  background-color: #eee;
  color: #202020;
}
.light .view-header__action:hover svg {
  stroke: #202020;
}
.light .view-header__action svg {
  stroke: grey;
}
