.loading-screen {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  place-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo__loading{
      margin-bottom: 2rem;
     
  }

  &__dark{
      background-color: #1f1f1f;
  }
  &__light{
      background-color: #fafafa;
  }

}
