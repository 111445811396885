.light .task {
  background-color: transparent;
  box-shadow: unset;
  border-color: #000 1f;
}
.light .task:hover {
  border-color: rgba(0, 0, 0, 0.2);
}
.light .task .task__text {
  color: inherit;
}
.light .task .task__date {
  color: unset;
}
.light .task__project-info {
  color: #555;
}
.light .empty-state__header {
  color: #202020;
}
.light .empty-state__body {
  color: #777;
}
.light .date__today {
  color: #058527 !important;
}
.light .date__tomorrow {
  color: #ad6200 !important;
}
.light .date__next-week {
  color: #692fc2 !important;
}
.light .date__weekend {
  color: #246fe0 !important;
}
.light .date__overdue {
  color: #dd4b39 !important;
}
