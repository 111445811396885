@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:wght@200;300;400&display=swap");
body {
  margin: 0;
  font-family: "SegoeUI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  overflow-y: hidden;
}
html {
  overflow: hidden;
}
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "SegoeUI";
  src: local("Segoe UI Light"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "SegoeUI";
  src: local("Segoe UI Semilight"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "SegoeUI";
  src: local("Segoe UI"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "SegoeUI";
  src: local("Segoe UI Bold"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "SegoeUI";
  src: local("Segoe UI Semibold"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
  font-weight: 700;
}
/* @font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"), url("assets/fonts/Segoe UI.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Italic"), url("assets/fonts/Segoe UI Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI SemiBold";
  font-style: normal;
  src: local("Segoe UI SemiBold"), url("assets/fonts/Segoe UI SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: bold;
  src: local("Segoe UI Bold"), url("assets/fonts/Segoe UI Bold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold Italic"), url("assets/fonts/Segoe UI Bold Italic.woff") format("woff");
} */

p {
  margin: unset;
}
ul {
  all: unset;
}
body {
  overflow: auto;
}
li {
  list-style: none;
}

* ~ input:focus {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #8b8b8b;
}

@media only screen and (max-width: 56.25em) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}
button {
  border: unset;
  border-radius: 5px;
  cursor: pointer;
  background-color: unset;
}

a {
  text-decoration: unset;
  color: inherit;
}

/* Todo: create appropriate file for this */
button.action {
  border-radius: 5px;
  font-weight: 600;
  padding: 0.6rem;
  max-width: 100%;
  border-radius: 5px;
  transition-duration: 0.3s;
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
button.action:not(:last-child) {
  margin-right: 0.4rem;
}
button:disabled {
  opacity: 0.5;
}

button.action__add-task,
button.action__add-project, .action__delete-project {
  background-color: #de4c4a;
  color: #feffff;
}
button.action__cancel {
  background-color: #f5f5f5;
}
button.action__cancel--dark {
  background-color: #292929;
  color: #feffff;
}
.action__add-project {
  padding: 6px 12px 7px !important;
}
