@font-face {
	font-family: "Graphik";
	src: url("../../assets/fonts/Graphik-Regular.woff2") format("woff2");
}
@font-face {
	font-family: "Graphik-semibold";
	src: url("../../assets/fonts/Graphik-Semibold.woff2") format("woff2");
}
.landing-page {
	overflow: hidden;
	-webkit-font-smoothing: antialiased;
	color: #575757;
	font-family: "Graphik", sans-serif;
	width: 100%;
	position: relative;
}
.landing-page .intro__header {
	width: 100%;
	height: 4rem;
	justify-content: center;
	position: fixed;
	top: 0;
	background-color: #fff;
	z-index: 4;
}
.landing-page .intro__nav {
	display: flex;
	justify-content: space-between;
	height: 100%;
	position: relative;
	align-items: center;
	flex: 1 1;
	min-height: 3rem;
	margin: 0 auto;
	max-width: 1088px;
	padding: 0 1rem;
}
.landing-page .intro__nav--group {
	display: flex;
	align-items: center;
}
.landing-page .intro__nav--group__wrapper {
	align-items: center;
	display: flex;
}
@media only screen and (max-width: 75em) {
	.landing-page .intro__nav--group__wrapper:nth-child(2) {
		display: none;
   }
}
.landing-page .intro__nav--item {
	height: 3rem;
	display: flex;
	place-items: center;
	cursor: pointer;
	padding: 0 1rem;
	font-size: 1rem;
}
.landing-page .intro__nav--item a {
	height: -webkit-fill-available;
}
.landing-page .intro__nav--link:hover {
	color: #1f1f1f;
	border-bottom: #e44232 1px solid;
	background-color: #fafafa;
}
.landing-page .intro__nav--cta {
	color: #fff;
	background-color: #e44232;
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0;
	font-family: "Graphik-semibold";
	padding: 0 1rem;
	min-height: 2.5rem;
	border-radius: 8px;
	display: flex;
	align-items: center;
}
.landing-page .nav-logo {
	margin-right: 1rem;
}
@media only screen and (max-width: 37.5em) {
	.landing-page .nav-logo .logo__desktop {
		display: none;
   }
}
.logo__mobile {
	display: none;
}
@media only screen and (max-width: 37.5em) {
	.logo__mobile {
		display: block;
		width: 32px;
		height: 32px;
   }
}
.section__intro {
	position: relative;
	overflow: hidden;
	text-align: center;
	white-space: pre-line;
}
@media only screen and (max-width: 56.25em) {
	.section__intro {
		height: 100vh;
   }
}
.section__intro .intro__hero {
	padding: 8rem 1rem 0;
	-webkit-margin-after: -10rem;
	position: relative;
	-webkit-margin-before: 3rem;
	margin-block-start: 3rem;
	z-index: 2;
	white-space: pre-line;
	text-align: center;
}
@media only screen and (max-width: 37.5em) {
	.section__intro .intro__hero {
		padding-top: 200px;
   }
}
.section__intro .intro__hero--cta {
	color: #fff;
	background-color: #e44232;
	font-size: 1rem;
	line-height: 1.25;
	letter-spacing: 0;
	font-family: "Graphik-semibold";
	padding: 0.2rem 1rem;
	min-height: 2.5rem;
	border-radius: 8px;
	margin-block-start: 10px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #e44232;
	min-width: 100px;
}
.section__intro .intro__hero--illustration-1 {
	display: block;
	margin: 0 auto;
	inline-size: 90%;
	max-inline-size: 1256px;
}
@media only screen and (max-width: 56.25em) {
	.section__intro .intro__hero--illustration-1 {
		margin-bottom: 0;
		position: fixed;
		bottom: 0;
		right: 50%;
		transform: translateX(50%);
   }
}
.section__intro .hero-text {
	font-size: 3.25rem;
	line-height: 1.05;
	letter-spacing: -0.04em;
	font-weight: 600;
	text-align: center;
	font-family: "Graphik-semibold", sans-serif;
	color: #1f1f1f;
	text-align: center;
	margin: auto;
	max-inline-size: 46.8rem;
	margin-block-end: 1rem;
	letter-spacing: -0.02em;
}
@media only screen and (max-width: 75em) {
	.section__intro .hero-text {
		width: 50%;
		font-size: 60px;
   }
}
@media only screen and (max-width: 56.25em) {
	.section__intro .hero-text {
		width: 80%;
   }
}
@media only screen and (max-width: 37.5em) {
	.section__intro .hero-text {
		font-size: 30px;
		width: 90%;
   }
}
.section__intro .hero-text__sub {
	line-height: 1.5;
	font-size: 19px;
	font-weight: 400;
	letter-spacing: -0.01em;
	margin-block-start: 0.83rem;
	margin-left: auto;
	margin-right: auto;
	margin-block-end: 1.5rem;
	max-inline-size: 480px;
	-webkit-margin-after: 24px;
	color: #1f1f1f;
}
@media only screen and (max-width: 37.5em) {
	.section__intro .hero-text__sub {
		font-size: 1rem;
   }
}
