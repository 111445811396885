.menu__trigger {
  position: absolute;
  right: -2.4rem;
  display: flex;
  border-radius: 2px;
  padding: 2px;
  opacity: 0;
  background-color: inherit;
  cursor: pointer;
}
.menu__trigger svg {
  stroke: grey;
}
.menu__trigger:hover {
  background-color: #363636;
}
.menu__trigger:hover svg {
  stroke: #fff de;
}
.menu__trigger--header {
  position: unset;
  opacity: 1;
}
.menu__trigger--header:hover {
  background-color: #4d4d4d;
}
